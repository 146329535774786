import { select, selectAll, event } from 'd3-selection'
import { transition } from 'd3-transition'
import { min, max, extent } from 'd3-array'
import { line, area, curveBasis, curveMonotoneX } from 'd3-shape'
import { axisBottom, axisLeft } from 'd3-axis'
import { scaleLinear, scaleTime, scaleOrdinal } from 'd3-scale'
import { utcHour, utcMinute } from 'd3-time'
import { zoom, zoomIdentity } from 'd3-zoom'
import { drag } from 'd3-drag'
import { interpolateObject } from 'd3-interpolate'
import { rgb } from 'd3-color'

export default {
  select,
  selectAll,
  get event() {
    // Prevent capture of event value, being null initially:
    // https://stackoverflow.com/a/40048292/3071369
    return event
  },
  transition,
  min,
  max,
  extent,
  line,
  area,
  curveBasis,
  curveMonotoneX,
  axisBottom,
  axisLeft,
  scaleLinear,
  scaleTime,
  scaleOrdinal,
  utcHour,
  utcMinute,
  zoom,
  zoomIdentity,
  drag,
  interpolateObject,
  rgb,
}
